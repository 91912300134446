import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, null, {
    "body-cell-file-name": _withCtx((props) => [
      _createVNode(_component_tm_icon, {
        class: "mr-2 distinct--text",
        name: "mic",
        size: "xLarge"
      }),
      _createElementVNode("span", null, _toDisplayString(props.row.fileName), 1)
    ]),
    "body-cell-actions": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_tm_button, {
          "icon-only": "",
          flat: "",
          color: "transparent"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_tm_icon, {
              size: "xLarge",
              name: "play_arrow"
            })
          ]),
          _: 1
        }),
        _createVNode(_component_tm_button, {
          "icon-only": "",
          flat: "",
          color: "transparent"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_tm_icon, {
              size: "xLarge",
              name: "delete"
            })
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}