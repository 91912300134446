
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import ComposeAudioRecordingsFilter from '@/components/pages/compose/ComposeAudioRecordingsFilter.vue'
import TmButton from '@/components/shared/TmButton.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import ComposeAudioRecordingsTable from '@/components/pages/compose/ComposeAudioRecordingsTable.vue'
import { getTableData } from '@/services/tableService'
import { formatDate } from '@/services/dateTimeService'
import type { ComposeAudioRecording } from '@/definitions/compose/types'

export default defineComponent({
  name: 'ComposeAudioRecordingsModal',
  components: { ComposeAudioRecordingsTable, TmButton, ComposeAudioRecordingsFilter, TmModal },
  props: {
    title: {
      type: String,
      default: 'Your audio recordings',
    },
  },
  setup() {
    const search = ref('')
    const selected = ref<ComposeAudioRecording[]>([])
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'File name', value: 'file-name' },
      { text: 'Length', value: 'length' },
      { text: 'File size', value: 'fileSize' },
      { text: 'Upload time', value: 'uploadTime', format: (val: string) => formatDate(val) },
    ])
    const tableItems = ref<ComposeAudioRecording[]>(getTableData('composeAudioRecordings'))

    return {
      search,
      selected,
      tableHeaders,
      tableItems,
    }
  },
})
